import { BoxProps, Flex, Text, TextProps } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { FormattedMessage } from 'react-intl';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';
import { ProductUVPPrice } from '@/components/Product/ProductUVPPrice/ProductUVPPrice';

import { Product } from '@/models/api';
import { AppService } from '@/services';

export type ProductPriceProps = Pick<
    Product,
    | 'grossPrice'
    | 'grossPriceBeforeDiscount'
    | 'currency'
    | 'productAvailableForSale'
    | 'discount'
    | 'code'
    | 'new'
    | 'notAvailableMessage'
> & {
    boxStyle?: BoxProps;
    regularPriceStyle?: TextProps;
    discountPriceStyle?: TextProps;
    productUnavailableStyle?: TextProps;
};

export const ProductPrice = (props: ProductPriceProps) => {
    const { getItemQuantity } = useShoppingCart();
    const {
        grossPrice,
        grossPriceBeforeDiscount,
        new: isNew,
        productAvailableForSale,
        discount,
        notAvailableMessage,
        boxStyle,
        regularPriceStyle,
        discountPriceStyle,
        productUnavailableStyle
    } = props;
    const currency = AppService.getInstanceCurrency();

    return (
        <>
            {(productAvailableForSale && grossPrice) ||
            (!productAvailableForSale && getItemQuantity(props.code)) ? (
                <Flex alignItems="center" gap={2} flexWrap="wrap" {...boxStyle}>
                    <Text fontWeight="semibold" whiteSpace="nowrap" {...regularPriceStyle}>
                        <FormattedPrice value={grossPrice} currency={currency} />
                    </Text>
                    <ProductUVPPrice
                        grossPriceBeforeDiscount={grossPriceBeforeDiscount}
                        discount={discount}
                        isNew={isNew}
                        currency={currency}
                        textStyle={discountPriceStyle}
                    />
                    {discount !== undefined && discount < 0 && (
                        <>
                            <Text
                                color="green.700"
                                fontSize="small"
                                fontWeight="medium"
                                bgColor="green.50"
                                paddingY="1px"
                                paddingX={1}
                                pos="relative"
                                {...productUnavailableStyle}
                            >
                                {discount.toString()}&#37;
                            </Text>
                        </>
                    )}
                </Flex>
            ) : (
                <Text color="grey.600">
                    {notAvailableMessage ? (
                        notAvailableMessage
                    ) : (
                        <FormattedMessage
                            id={
                                grossPrice !== null
                                    ? 'product.unavailable'
                                    : 'product.not-available-in-catalog'
                            }
                        />
                    )}
                </Text>
            )}
        </>
    );
};
