import { ButtonProps, useToken, VisuallyHidden } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { signOut, useSession } from 'next-auth/react';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getAxiosResponseErrorObject } from '@/lib';
import { useToast } from '@/hooks/useToast';

import { Button } from '@/components/UI/Buttons/Button';

import BasketConfig from '@/constants/basket';
import RoutePath from '@/constants/route-path';
import { ErrorCode } from '@/models/api/Error';
import { AddToCartProps, ProductIdProps } from '@/models/props/ShoppingCartContextProps';

import { BasketIcon } from '../Icons';
import { useApp } from '../../../context/appContext';

const AddToCart = (props: AddToCartProps & ButtonProps) => {
    const { orderingAllowed, showDisabledOrderingPopup } = useApp();
    const {
        code,
        grossPrice,
        isCatalogue,
        quantity,
        setQuantity,
        icon,
        maxOrder = 30,
        variant = 'full',
        ...rest
    } = props;
    const {
        increaseItemQuantity,
        cartItems,
        disableCartActions,
        unloggedIncreaseItemQuantity,
        cartItemsUnlogged
    } = useShoppingCart();
    const session = useSession();
    const { errorToast, infoToast, successToast } = useToast();
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const obj = useMemo(
        () => ({ code, quantity, setQuantity, icon, maxOrder, grossPrice, isCatalogue, intl }),
        [code, quantity, setQuantity, icon, maxOrder, grossPrice, isCatalogue, intl]
    );
    const catalogue = obj.isCatalogue ?? false;
    const isLogged = useMemo(() => session?.status === 'authenticated', [session]);
    const [brand500Color] = useToken('colors', ['brand.main']);
    const handleAddToCart = useCallback(
        async (id: ProductIdProps, quantity: number, grossPrice?: string) => {
            if (!orderingAllowed) {
                showDisabledOrderingPopup();
                return;
            }

            setIsLoading(true);

            if (obj.maxOrder !== undefined && quantity > obj.maxOrder) {
                infoToast({
                    description: obj.intl.formatMessage(
                        {
                            id: 'basket.reached-max-order'
                        },
                        { maxItems: obj.maxOrder.toString() }
                    )
                });
                setIsLoading(false);
                return;
            }

            const cartItemsList = isLogged ? cartItems : cartItemsUnlogged;

            if (cartItemsList.length < (BasketConfig.pageSize as number)) {
                try {
                    if (isLogged) {
                        await increaseItemQuantity(id, obj.quantity);
                    } else {
                        await unloggedIncreaseItemQuantity(
                            id,
                            quantity,
                            isLogged ? 0 : +grossPrice!.replace(',', '.'),
                            catalogue
                        );
                    }
                    obj.setQuantity?.(1);
                    successToast({ title: obj.intl.formatMessage({ id: 'added-to-cart' }) });
                } catch (e) {
                    const error = getAxiosResponseErrorObject(e);
                    const title = obj.intl.formatMessage({
                        id: 'add-to-cart.update-product-quantity.fail'
                    });
                    const description =
                        error?.errors?.length > 0
                            ? obj.intl.formatMessage({
                                  id: `add-to-cart.error.${error.errors[0].code}`
                              }) || error.errors[0].code
                            : '';

                    errorToast({ title, description });

                    if (error?.errors?.[0]?.code === ErrorCode.disabled) {
                        disableCartActions(true);
                    }

                    if (error?.errors?.[0]?.code === ErrorCode.unauthenticated) {
                        signOut({ callbackUrl: RoutePath.Home });
                    }
                }
            } else {
                errorToast({
                    title: obj.intl.formatMessage({ id: 'add-to-cart.max-page-size.title' }),
                    description: obj.intl.formatMessage({
                        id: 'add-to-cart.max-page-size.description'
                    })
                });
            }

            setIsLoading(false);
        },
        [
            cartItems,
            cartItemsUnlogged,
            disableCartActions,
            catalogue,
            errorToast,
            increaseItemQuantity,
            infoToast,
            orderingAllowed,
            showDisabledOrderingPopup,
            successToast,
            unloggedIncreaseItemQuantity,
            isLogged,
            obj
        ]
    );

    return (
        <Button
            onClick={() => handleAddToCart(obj.code, obj.quantity, obj.grossPrice as string)}
            display="flex"
            justifyContent="center"
            minW={28}
            width="100%"
            px={4}
            backgroundColor={variant === 'full' ? 'brand.darker' : 'accent.main'}
            color={variant === 'full' ? 'white' : 'black'}
            h="46px"
            disabled={!orderingAllowed}
            isLoading={isLoading}
            _hover={{ backgroundColor: variant === 'simple' ? 'accent.600' : 'brand.darkest' }}
            _active={{ backgroundColor: variant === 'simple' ? 'accent.600' : 'brand.darkest' }}
            _focusVisible={{ boxShadow: `0 0 0 3px ${brand500Color} inset` }}
            {...rest}
        >
            {variant === 'full' ? (
                <FormattedMessage id="add-to-cart" />
            ) : (
                <>
                    <VisuallyHidden>
                        <FormattedMessage id="add-to-cart" />
                    </VisuallyHidden>
                    <BasketIcon pointerEvents="none" h="20px" w="auto" />
                </>
            )}
        </Button>
    );
};

export default AddToCart;
