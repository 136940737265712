import { Box, ButtonProps } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useToast } from '@/hooks/useToast';

import { Button } from '@/components/UI/Buttons/Button';

import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';
import { ProductService } from '@/services';

type UnavailableProductButtonProps = {
    code: ProductIdProps;
    button?: ButtonProps;
    subscribed?: boolean;
};

export const UnavailableProductButton = (props: UnavailableProductButtonProps) => {
    const { code, button, subscribed } = props;
    const intl = useIntl();
    const { errorToast, successToast } = useToast();
    const [inProgress, setInProgress] = useState(false);
    const [isDisabled, setIsDisabled] = useState(subscribed || false);

    const buttonClickHandler = useCallback(async () => {
        setInProgress(() => true);
        setIsDisabled(true);
        try {
            await ProductService.subscribeProductAvailabilityNotification(code);
            successToast({
                description: intl.formatMessage({
                    id: 'product.availability-notification-subscribe.success'
                })
            });
        } catch (error) {
            setIsDisabled(false);
            errorToast({
                description: intl.formatMessage({
                    id: 'product.availability-notification-subscribe.fail'
                })
            });
        }
        setInProgress(() => false);
    }, [code, intl, successToast, errorToast]);

    return (
        <Box pt={5} display="flex">
            <Button
                w="100%"
                h="52px"
                isDisabled={isDisabled}
                whiteSpace="normal"
                variant="outline"
                colorScheme="brand"
                isLoading={inProgress}
                onClick={buttonClickHandler}
                {...button}
            >
                <FormattedMessage id="unavailable-products.inform" />
            </Button>
        </Box>
    );
};
