import { Box, List, ListItem } from '@chakra-ui/react';

import { FileIcon } from '@/components/Icons';
import NavLink from '@/components/UI/Links/NavLink';

import { ProductFiles } from '@/models/props/ProductCardProps';
import { StrapiUtilsService } from '@/services/StrapiUtilsService';

type ProductAttachmentsProps = {
    files: ProductFiles[] | undefined;
};

export const ProductAttachments = ({ files }: ProductAttachmentsProps) => {
    const listItem = files?.map((item, index) => {
        const { attributes } = item;
        const { name } = attributes;
        const url = StrapiUtilsService.updateUploadsUrl(attributes.url);
        return (
            <ListItem key={index}>
                <NavLink
                    href={url}
                    download={name}
                    isExternal
                    display="inline-flex"
                    alignItems="center"
                >
                    <FileIcon mr={3} mt={-1} stroke="brand.main" fontSize="xl" fill="none" /> {name}
                </NavLink>
            </ListItem>
        );
    });

    if (!files?.length) {
        return <></>;
    }

    return (
        <Box>
            <List
                display="grid"
                gridTemplateColumns={{
                    base: 'minmax(0, 1fr)',
                    xl: 'minmax(auto, 400px) 1fr'
                }}
                columnGap={5}
                rowGap={6}
            >
                {listItem}
            </List>
        </Box>
    );
};
